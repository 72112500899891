import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

export default function BlogPage({ data, pageContext }) {
  return  (
      <Layout>
      <Helmet>
        <title>わく☆すたブログ {
          pageContext.isFirst ? `TOP` : `ページ ${pageContext.currentPage}/${pageContext.blogPages}`
        } </title>
      </Helmet>
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="archive_title_wrap">
                <h1 className="archive_title">わく☆すたブログ {
                    pageContext.isFirst ? `TOP` : `ページ ${pageContext.currentPage}/${pageContext.blogPages}`
                } </h1>
              </div>
              <div className="keni-section_wrap keni-section_wrap_style02">
                <div className="keni-section">
                  <div className="entry-list entry-list_style01">
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                      <article class="entry-list_item">
                        <div className="entry">
                          <figure className="entry_thumb">
                            <img src={ node.frontmatter.thumbnail || `/images/wakusuta_sky.jpg` } alt="" />
                          </figure>
                          <div className="entry_inner">
                                <h2 className="entry_title"><a href={node.fields.slug}>{node.frontmatter.title}</a></h2>
                                <div className="news-list_item_date">
                                  <time datetime={node.frontmatter.date} itemprop="datePublished" content={node.frontmatter.date}>
                                    {node.frontmatter.dateJP}
                                  </time>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                <div className="ently_read-more">
                                  <a href={node.fields.slug}  class="btn dir-arw_r"><span class="icon_arrow_s_right"></span>続きを読む</a>
                                </div>
                          </div>
                        </div>
                      </article>
                    ))}
                  </div>
                  <div class="page-nav"><ul>
                    {!pageContext.isFirst && (
                      <li class="page-nav_prev">
                        <a href={ pageContext.currentPage === 2 ? `/blog/` :
                          `/blog/page/${pageContext.currentPage - 1}/`}>新しい記事</a>
                      </li>
                    )}
                    {!pageContext.isLast && (
                      <li class="page-nav_next">
                        <a href={`/blog/page/${pageContext.currentPage + 1}/`} >以前の記事</a>
                      </li>
                    )}
                  </ul></div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      </Layout>
    )
}

export const query = graphql`
    query BlogQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: {fields: frontmatter___date, order:DESC},
            filter: {frontmatter: {pagetype: { eq: "blog" }}}
            skip: $skip
            limit: $limit
          ) {
            edges {
                node {
                    frontmatter {
                        title
                        dateJP:date(formatString: "YYYY年MM月DD日")
                        date(formatString: "YYYY-MM-DD")
                        thumbnail
                    }
                    fields {
                        slug
                    }
                    excerpt(format: MARKDOWN)
                }
            }
        }
    }
`